import React, { useEffect } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie'
import {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
} from '../slice/categorySlice';
import { baseUrl } from '../api/api';

function CategorySidebar({ isOpen, toggleSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const categories = useSelector((state) => state.categories.categories);
  console.log(categories,"sidebarcate")
  const loading = useSelector((state) => state.categories.loading);
  const error = useSelector((state) => state.categories.error);

  useEffect(() => {
    const fetchCategories = async () => {
      dispatch(fetchCategoriesStart());
      try {
        const response = await axios.get(`${baseUrl}/api/category`);
        dispatch(fetchCategoriesSuccess(response.data));
      } catch (err) {
        dispatch(fetchCategoriesFailure(err.message));
      }
    };

    fetchCategories();
  }, [dispatch]);

  const handleCategoryClick = (category) => {
    // navigate(`/channel/${category.replace(/%20/g, ' ')}`);
    navigate(`/channel/${category}`);

    toggleSidebar();
  };

  const handleLogout=(e)=>{
    navigate('/login')
    Cookies.remove('number')
  }

  return (
    <div className={`fixed z-10 lg:top-2 top-1 left-0 h-full w-64 bg-black text-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
      <button onClick={toggleSidebar} className="p-4 text-right">
        <IoCloseSharp size={25}/>
      </button>
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4">CHANNELS</h2>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <ul>
          {categories.map((category, index) => (
            <li
              key={index}
              onClick={() => handleCategoryClick(category.channel_name)} 
              className="lg:w-[200px] mb-2 text-lg hover:bg-gray-100 text-white hover:rounded-lg hover:text-black hover:px-2 gap-4 py-2 cursor-pointer"
            >
              {category.channel_name} 
            </li>
          ))}
        </ul>
        {/* <button onClick={handleLogout}
        className='mt-4 mb-2 text-lg bg-white text-black hover:bg-gray-100 rounded-lg hover:px-2 gap-4
       lg:w-[200px] w-[160px] py-2 cursor-pointer'>LOGOUT</button> */}
      </div>
    </div>
  );
}

export default CategorySidebar;
