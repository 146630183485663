import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
// import Navbar from './pages/Navbar';
import HomePage from './components/HomePage';
import VideoPlayerPage from './pages/VideoPlayerPage';
import Login from './components/Login';
// import UploadContent from './components/UploadContent';
import Dashboard from './components/Dashboard';
// import UserVideos from './pages/UserVideos';
import UserPlayer from './pages/UserPlayer';
// import DoiRedirect from './components/DoiRedirect';
import ChannelVideos from './components/ChannelVideos';
function App() {
  return (
    <div className='bg-[#C7A4E9] min-h-screen '>
<Router>
  {/* <Navbar/> */}
  <Routes>
    <Route path='/login' element={<Login/>}/>
    {/* <Route path='/DoiRedirect' element={<DoiRedirect/>}/> */}
    <Route path='/' element={<HomePage/>}/>
    <Route path="/video/:id" element={<VideoPlayerPage/>} />
    <Route path="/myvideo/:id" element={<UserPlayer/>} />
    <Route path="/channel/:channelName" element={<ChannelVideos/>} />


    {/* <Route path ="/upload" element={<UploadContent/>}/> */}
    {/* <Route path ="/upload" element={<Dashboard/>}/> */}


  </Routes>
</Router>
    </div>
  );
}

export default App;
